import React, { useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { DeviceType } from '../../../../../shared/types/deviceType';
import InsertDeviceIntoInspectionForm from '../InsertDeviceIntoInspectionForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';

interface Props {
  onClose: () => any;
  open: boolean;
  placeId: string | number;
  refreshParent: () => any;
  inspectionId: number;
}

interface Data {
  deviceType: DeviceType;
}

const InsertDeviceIntoInspectionModal = (props: Props) => {
  const { refreshParent, onClose, placeId, inspectionId, open } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const {
    data: devicesData,
    isLoading: isLoadingDevices,
    isLoaded: isLoadedDevices,
    error: devicesError,
  } = useFetch<any>({
    initialValue: { devices: [] },
    fetchAction: () => api({}).get(`/places/${placeId}/devices`),
  });
  const form = useForm({
    initialValues: {
      deviceUuid: undefined,
    },
    validate: values => {
      if (!values.deviceUuid) {
        return { deviceUuid: 'Urządzenie jest wymagane' };
      }
      return null;
    },
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      if (!formValues.deviceUuid) {
        toast.error('Urządzenie jest wymagane');
        return;
      }
      const body = {
        deviceUuid: formValues.deviceUuid,
      };
      await api({}).post(`/api/places/${placeId}/inspections/${inspectionId}/devices`, body);
      toast.success('Urządzenie zostało dodane do przeglądu');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      if (e?.response?.data?.code) {
        toast.error(
          e.response?.data?.code === 'DEVICE_ALREADY_IN_INSPECTION'
            ? 'Urządzenie jest już w przeglądzie'
            : translations.global.somethingWentWrong,
        );
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal opened={open} onClose={handleClose} title={'Dodaj urządzenie'}>
      <LoadingOverlay visible={isLoadingDevices} />
      {devicesError && (
        <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
          {translations.global.somethingWentWrong}
        </Alert>
      )}

      <form onSubmit={form.onSubmit(onSubmit)} style={{ minHeight: 300 }}>
        {!devicesError && (
          <InsertDeviceIntoInspectionForm form={form} devices={devicesData.devices} />
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit">
            {translations.global.add}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default InsertDeviceIntoInspectionModal;
