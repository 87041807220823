import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import { Button, Group, Modal } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';

import { InspectionAdditionalFields } from '../Inspection/types';
import EditInspectionAdditionalFieldsForm from '../EditInspectionAdditionalFieldsForm';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  inspectionId: number;
  open: boolean;
  additionalFields: {};
}

interface Data {
  action: {
    deviceTypeAction: {
      name: string;
    };
    note: string;
    amount: number;
    createdAt: string;
  };
}

const EditInspectionAdditionalFieldsModal = (props: Props) => {
  const { refreshParent, onClose, placeId, inspectionId, open, additionalFields } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const form = useForm<InspectionAdditionalFields>({
    initialValues: {
      repairOrders: '',
      additionalTreatments: '',
      batchNumberOfUsedPreparations: '',
      reasonForNotScanning: '',
    },
  });

  useEffect(() => {
    if (open && additionalFields) {
      form.setValues(additionalFields);
    }
  }, [open, additionalFields]);

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        additionalFields: formValues,
      };
      await api({}).patch(
        `/api/places/${placeId}/inspections/${inspectionId}/additional-fields`,
        body,
      );
      toast.success('Dodatkowe informacje zostały zaktualizowane');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <Modal opened={open} onClose={handleClose} title={'Edytuj dodatkowe informacje'}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <EditInspectionAdditionalFieldsForm form={form} />
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={isSaving}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditInspectionAdditionalFieldsModal;
