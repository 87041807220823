import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';

import { useTranslations } from 'shared/translations/useTranslations';
import { Alert, Button, Group, Modal, Notification } from '@mantine/core';

interface Props {
  deviceId?: number;
  refreshParent: () => any;
  onClose: () => any;
  placeId: string | number;
  inspectionId: number;
  open: boolean;
}

const DeleteDeviceFromInspectionModal = (props: Props) => {
  const { deviceId, refreshParent, onClose, placeId, inspectionId, open } = props;
  const translations = useTranslations();

  const [isSaving, setSaving] = useState(false);
  const handleClick = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(
        `/api/places/${placeId}/inspections/${inspectionId}/devices/${deviceId}`,
      );
      toast.success('Urządzenie zostało usunięte');
      setSaving(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <Modal opened={open} onClose={handleClose} title="Usuń urządzenie z inspekcji">
      Czy napewno chcesz usunąć te urządzenie z inspekcji?
      <Group position="right" mt={20}>
        <Button color="primary" loading={isSaving} type="submit" onClick={handleClick}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteDeviceFromInspectionModal;
