import React, { useEffect, useMemo, useRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { routes } from 'shared/constants/routes';
import { NavContext } from 'shared/context/nav';
import { performLogout } from 'modules/Auth/reducers/login';
import NavigationItem from '../../components/NavigationItem';
import { PowerOff } from 'shared/components/Icons';
// import { ReactComponent as Logo } from 'assets/logo_white.svg';

import MouseeShield from 'assets/mousee_shield.png';
import './styles.scss';
import {
  getUserTypeLabel,
  isCompanyAdminByAuthState,
  isSuperAdminByAuthState,
} from 'shared/utils/user';
import { Link } from 'react-router-dom';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';
import { LanguageSelector } from 'shared/components/LanguageSelector';
import { translationService } from 'shared/translations/translations';
import { Alert, Center, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import enterpriseClientService from '../../../Auth/containers/EnterpriseClient/enterpriseClient.service';
import NavigationSubscription from '../NavigationSubscription';
import { UserType } from '../../../../shared/types/User';
import { useDashboardQuery } from '../../../../queries/dashboard';

const Navigation = ({
  activeRoute,
  role,
  performLogout: logout,
  isSuperAdmin,
  isCompanyAdmin,
  currentUser,
}) => {
  const translations = useMemo(() => translationService.getTranslations(), []);
  const { data, isLoading, isLoaded, error, refresh } = useFetch({
    initialValue: { categories: [] },
    fetchAction: () => api({}).get(`/api/recommendations/count`),
  });
  const dashboardData = useDashboardQuery();

  useEffect(() => {
    const id = setInterval(() => {
      refresh();
    }, 60000 * 10); // Every 10 minutes
    return () => {
      clearInterval(id);
    };
  }, []);

  const dashboardAlertCount = useMemo(() => {
    if (dashboardData.isLoading || dashboardData.isError || !dashboardData.data) {
      return 0;
    }
    return (
      dashboardData.data.electronicDevices.alertCount +
      dashboardData.data.schedule.todayUpcomingEventsCount
    );
  }, [dashboardData.data]);
  return (
    <div className="Navigation">
      <NavContext.Provider value={{ activeRoute }}>
        <Center mb={20}>
          <img src={MouseeShield} className="Navigation__logo" alt="Mousee" />
        </Center>
        <div className="Navigation__inner">
          <NavigationItem
            to={routes.dashboard.default.path}
            tooltip={'Dashboard'}
            icon={
              dashboardAlertCount > 0 ? (
                <div className="Navigation__counter">{dashboardAlertCount}</div>
              ) : (
                <i className="fas fa-tachometer-alt" />
              )
            }
          />
          <NavigationItem
            to={routes.reports.default.path}
            tooltip={translations.global.reports}
            icon={<i className="fa fa-file-invoice" />}
          />
          <NavigationItem
            to={routes.statistics.default.path}
            tooltip={
              <div>
                {translations.global.statistics} <sup>beta</sup>
              </div>
            }
            icon={<i className="fas fa-chart-bar" />}
          />
          <NavigationItem
            to={routes.schedule.default.path}
            tooltip={<div>{translations.schedule.title}</div>}
            icon={<i className="fa fa-calendar" />}
          />
          {(isSuperAdmin || isCompanyAdmin) && (
            <NavigationItem
              to={routes.companies.default.path}
              tooltip={isSuperAdmin ? translations.global.companies : translations.global.myCompany}
              icon={<i className="fas fa-building" />}
            />
          )}

          <NavigationItem
            to={routes.places.default.path}
            tooltip={translations.global.facilities}
            icon={<i className="fas fa-warehouse" />}
          />

          {[
            UserType.COMPANY_ADMIN,
            UserType.SUPER_ADMIN,
            UserType.TECHNICIAN,
            UserType.CONTROLLER,
          ].includes(currentUser?.type) && (
            <NavigationItem
              to={routes.lora.devices.default.path}
              tooltip={`LoRa`}
              icon={<i className="fa fa-mobile" />}>
              <NavigationItem
                to={routes.lora.devices.default.path}
                sub
                tooltip={translations.global.devices}
              />
              {/* <NavigationItem
                to={routes.lora.gateways.default.path}
                sub
                tooltip={'Gatewaye'}
              /> */}
            </NavigationItem>
          )}
          {isCompanyAdmin && (
            <NavigationItem
              to={routes.deviceTypes.default.path}
              tooltip={translations.global.deviceTypes}
              icon="DT">
              <NavigationItem
                to={routes.deviceTypes.actions.path}
                sub
                tooltip={translations.global.actions}
              />
              <NavigationItem
                to={routes.deviceTypes.objects.path}
                sub
                tooltip={translations.global.pestRelated}
              />
              <NavigationItem
                to={routes.deviceTypes.observations.path}
                sub
                tooltip={translations.global.observations}
              />
            </NavigationItem>
          )}
          {(isSuperAdmin || isCompanyAdmin) && (
            <NavigationItem
              to={routes.users.default.path}
              tooltip={translations.global.users}
              icon={<i className="fa fa-users" />}
            />
          )}
          {(isSuperAdmin || isCompanyAdmin) && (
            <NavigationItem
              to={routes.qrCodes.default.path}
              tooltip={translations.qrCodes.title}
              icon={<i className="fa fa-barcode" />}
            />
          )}
          <NavigationItem
            to={routes.recommendation.default.path}
            tooltip={translations.global.recommendations}
            icon={data.count > 0 ? <div className="Navigation__counter">{data.count}</div> : 'Z'}>
            <NavigationItem
              to={routes.recommendation.statistics.default.path}
              sub
              tooltip={translations.global.statistics}
            />
          </NavigationItem>
          {isSuperAdmin && (
            <NavigationItem to={routes.admin.default.path} tooltip="Admin" icon={'A'}>
              <NavigationItem
                to={routes.admin.recommendation.default.path}
                sub
                tooltip={translations.global.recommendations}
              />
              <NavigationItem
                to={routes.admin.visualInspection.default.path}
                sub
                tooltip={translations.global.visualInspection}
              />
              <NavigationItem
                to={routes.admin.mixtures.default.path}
                sub
                tooltip={translations.mixtures.title}
              />
              <NavigationItem
                to={routes.admin.devices.default.path}
                sub
                tooltip={translations.global.devices}
              />
              {/* <NavigationItem
                to={routes.admin.mixtures.default.path}
                sub
                tooltip={"Szkodniki"}
              />
              <NavigationItem
                to={routes.admin.mixtures.default.path}
                sub
                tooltip={"Szkodniki"}
              /> */}
            </NavigationItem>
          )}
        </div>
        <NavigationSubscription />
        {/* <LanguageSelector /> */}

        <NavigationItem
          onClick={e => {
            e.preventDefault();
            logout();
          }}
          tooltip={translations.global.signOut}
          icon={<PowerOff />}
        />
      </NavContext.Provider>
    </div>
  );
};
const mapStateToProps = ({ router, auth }) => ({
  activeRoute: router.location.pathname,
  isSuperAdmin: isSuperAdminByAuthState(auth),
  isCompanyAdmin: isCompanyAdminByAuthState(auth),
  currentUser: auth.data.user.data,
});
const mapDispatchToProps = dispatch => bindActionCreators({ performLogout }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navigation);
