import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import EditInspectionActionForm from '../EditInspectionActionForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';
import { DeviceType } from '../../../../../shared/types/deviceType';
import EditInspectionObjectForm from '../EditInspectionObjectForm';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  open: boolean;
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  inspectionId: number;
  deviceTypeId: number;
}

const CreateInspectionObjectForDeviceTypeModal = (props: Props) => {
  const { refreshParent, onClose, placeId, inspectionId, deviceTypeId, open } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<DeviceType>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/places/${placeId}/device-types/${deviceTypeId}`),
  });

  const form = useForm({
    initialValues: {
      name: '',
      amount: 1,
      note: '',
    },
    schema: zodResolver(schema),
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        deviceTypeObjectId: formValues.deviceTypeObjectId,
        note: formValues.note,
        amount: formValues.amount,
      };
      await api({}).post(
        `/api/places/${placeId}/inspections/${inspectionId}/device-types/${deviceTypeId}/objects`,
        body,
      );
      toast.success('Szkodniki zostały dodane');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  useEffect(() => {
    if (open && deviceTypeId) {
      refresh();
    }
  }, [open]);

  return (
    <Modal opened={open} onClose={handleClose} title={'Dodaj akcję'}>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && (
          <EditInspectionObjectForm
            form={form}
            isCreating
            deviceTypeObjects={
              data?.deviceTypeObjects?.map(dta => ({
                label: dta.name,
                value: dta.id,
              })) || []
            }
          />
        )}
        <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
          Dodając nowego szkodnika <strong>należy pamiętać aby </strong>
          później manualnie edytować kolejne lub poprzednie akcję czy szkodniki. Inaczej wartości
          mogą się nie zgadzać. Tyczy się to również zmian wymaganych w innych przeglądach.
          <br />
          <br />
          Podczas dodania szkodnika , <strong>data</strong> jest ustawiona na datę stworzenia
          przeglądu. Później można ją edytować.
          <br />
          <br />
          <strong>Uwaga:</strong> Akcje czyszczenia lepa należy dodawać przed dodaniem szkodnika w
          danym przeglądzie, inaczej wpłynie to na poprawne wyliczenia statystyki
        </Alert>
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit">
            {translations.global.add}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CreateInspectionObjectForDeviceTypeModal;
