import React from 'react';
import { Select } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  form: any;
  devices?: any[];
}

const InsertDeviceIntoInspectionForm = (props: Props) => {
  const { form, devices } = props;
  const translations = useTranslations();

  return (
    <>
      <Select
        label={translations.global.device}
        name="deviceUuid"
        data={devices?.map(d => ({ label: d.uniqueId, value: d.uuid })) || []}
        required
        searchable
        mb={20}
        {...form.getInputProps('deviceUuid')}
      />
    </>
  );
};

export default InsertDeviceIntoInspectionForm;
