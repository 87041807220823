import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import {
  Button,
  Title,
  LoadingOverlay,
  SimpleGrid,
  Alert,
  Breadcrumbs,
  Group,
  Loader,
  Popover,
  Flex,
} from '@mantine/core';
import { useHistory, useParams } from 'react-router';

import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import useFetch from 'shared/hooks/useFetch';

import { routes } from '../../../../shared/constants/routes';
import { Link } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { X, Check, AlertCircle } from 'tabler-icons-react';
import LoraDeviceData from '../../shared/LoraDeviceData';
import useCurrentUser from '../../../../shared/hooks/useCurrentUser';
import { UserType } from '../../../../shared/types/User';

interface AwsData {
  awsUuid?: string;
  error?: 'NOT_FOUND';
}

const Manage = () => {
  const { loraDeviceUuid } = useParams<{
    loraDeviceUuid?: string;
  }>();

  const translations = useTranslations();
  const user = useCurrentUser();

  const {
    isLoading,
    isLoaded,
    data,
    error,
    refresh: fetchLoraDevice,
  } = useFetch<{ loraDevice: LoraDevice; awsData: AwsData }>({
    fetchAction: () => apiService({}).get(`/api/lora/devices/${loraDeviceUuid}`),
    fetchActionDeps: [loraDeviceUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });

  useEffect(() => {
    if (loraDeviceUuid) {
      fetchLoraDevice();
      // fetchLoraDeviceUplinks();
    }
  }, [loraDeviceUuid]);
  const loraDevice = data?.loraDevice;

  const items = [
    { title: 'LoRa', href: routes.lora.default.path },
    { title: translations.loraDevice.list.devices, href: routes.lora.devices.default.path },
    { title: data?.loraDevice.name },
  ].map((item, index) =>
    item.href ? (
      <Link to={item.href} key={index}>
        <span className="g-breadcrumb"> {item.title}</span>
      </Link>
    ) : (
      <span key={index}>{item.title}</span>
    ),
  );
  const history = useHistory();
  const [isDeletingLoraDevice, setIsDeletingLoraDevice] = useState(false);
  const [showDeletingModal, setShowDeletingModal] = useState(false);
  const [isSendingAlert, setIsSendingAlert] = useState(false);
  const [showSendAlertModal, setShowSendAlertModal] = useState(false);

  const onClickSendAlert = useCallback(async () => {
    setIsSendingAlert(true);
    try {
      await apiService({}).post(`/api/lora/devices/${loraDeviceUuid}/manual-set-detection`);
      notifications.show({
        title: 'Sukces',
        message: 'Wysłano alert', // Todo: translations
        color: 'green',
        icon: <Check size="1rem" />,
      });
      fetchLoraDevice();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: translations.global.somethingWentWrong,
        color: 'red',
        icon: <X size="1rem" />,
      });
    } finally {
      setIsSendingAlert(false);
    }
  }, [setIsSendingAlert, loraDeviceUuid]);

  const onClickDelete = useCallback(async () => {
    setIsDeletingLoraDevice(true);
    try {
      await apiService({}).delete(`/api/lora/devices/${loraDeviceUuid}`);
      history.push(routes.lora.devices.default.path);
      notifications.show({
        title: 'Sukces',
        message: 'Usunięto urządzenie', // Todo: translations
        color: 'green',
        icon: <Check size="1rem" />,
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: translations.global.somethingWentWrong,
        color: 'red',
        icon: <X size="1rem" />,
      });
    } finally {
      setIsDeletingLoraDevice(false);
    }
  }, [setIsDeletingLoraDevice, loraDeviceUuid]);

  const ref = React.useRef<any>();
  const isSuperAdmin = user.type === UserType.SUPER_ADMIN;
  return (
    <AppContent>
      <Breadcrumbs>{items}</Breadcrumbs>
      {/* <LoadingOverlay visible={isLoading || isDeletingLoraDevice} overlayBlur={1} /> */}
      {(isLoading || isDeletingLoraDevice || isSendingAlert) && (
        <Flex justify="center" align="center" style={{ minHeight: '100vh' }}>
          <Loader />
        </Flex>
      )}

      {error && (
        <Flex justify="center" align="center" style={{ minHeight: '100vh' }}>
          <Alert icon={<AlertCircle size="1rem" />} title="Error" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        </Flex>
      )}
      {isLoaded && (
        <div>
          <Flex align="center" justify="start" gap={10} mt={20} mb={20}>
            <Title>{loraDevice?.name}</Title>
            {isSuperAdmin && (
              <Group>
                <Popover
                  width={200}
                  position="bottom"
                  withArrow
                  shadow="md"
                  opened={showDeletingModal}>
                  {/* <Popover. */}
                  <Popover.Target>
                    <Button
                      color={'red'}
                      onClick={() => setShowDeletingModal(true)}
                      loading={isDeletingLoraDevice}>
                      {translations.global.delete}
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown style={{ minWidth: 300 }}>
                    {translations.loraDevice.deleteConfirmation}
                    <Group mt={10} style={{ minWidth: 300 }}>
                      <Button
                        onClick={() => {
                          onClickDelete();
                          setShowDeletingModal(false);
                        }}
                        color={'red'}>
                        {translations.global.yes}, {translations.global.delete}
                      </Button>
                      <Button
                        onClick={() => {
                          setShowDeletingModal(false);
                        }}
                        variant="outline">
                        {translations.global.cancel}
                      </Button>
                    </Group>
                  </Popover.Dropdown>
                </Popover>

                <Button
                  onClick={() => {
                    history.push(routes.lora.devices.edit.to(loraDeviceUuid));
                  }}>
                  {translations.global.edit}
                </Button>

                {[UserType.SUPER_ADMIN].includes(user.type) && (
                  <Popover
                    width={200}
                    position="bottom"
                    withArrow
                    shadow="md"
                    opened={showSendAlertModal}>
                    {/* <Popover. */}
                    <Popover.Target>
                      <Button
                        onClick={() => setShowSendAlertModal(true)}
                        variant="outline"
                        color="blue"
                        loading={isSendingAlert}>
                        Wyślij alert
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown style={{ minWidth: 300 }}>
                      Czy na pewno chcesz wysłać alert?
                      <Group mt={10} style={{ minWidth: 300 }}>
                        <Button
                          onClick={() => {
                            onClickSendAlert();
                            setShowSendAlertModal(false);
                          }}
                          color={'blue'}>
                          {translations.global.yes}
                        </Button>
                        <Button
                          onClick={() => {
                            setShowSendAlertModal(false);
                          }}
                          variant="outline">
                          {translations.global.cancel}
                        </Button>
                      </Group>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </Group>
            )}
            {/* <Title>{translations.loraDevice.details}</Title> */}
          </Flex>
          {loraDevice?.uuid && (
            <LoraDeviceData refresh={fetchLoraDevice} loraDevice={data?.loraDevice} />
          )}
        </div>
      )}
    </AppContent>
  );
};

export default Manage;
