import React from 'react';
import { Textarea } from '@mantine/core';

interface Props {
  form: any;
}

const EditInspectionAdditionalFieldsForm = (props: Props) => {
  const { form } = props;

  return (
    <>
      <Textarea
        label={'Uwagi/zlecenia naprawy'}
        name="repairOrders"
        mb={20}
        {...form.getInputProps('repairOrders')}
      />
      <Textarea
        label={'Dodatkowe zabiegi'}
        name="additionalTreatments"
        mb={20}
        {...form.getInputProps('additionalTreatments')}
      />
      <Textarea
        label={'Numery partii zużytych preparatów'}
        name="batchNumberOfUsedPreparations"
        mb={20}
        {...form.getInputProps('batchNumberOfUsedPreparations')}
      />
      <Textarea
        label={'Powód niezeskanowania urządzeń'}
        name="reasonForNotScanning"
        mb={20}
        {...form.getInputProps('reasonForNotScanning')}
      />
    </>
  );
};

export default EditInspectionAdditionalFieldsForm;
