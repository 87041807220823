import { Alert, Button, Group, Loader, Modal, NumberInput, Radio, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LoraDevice } from '../../../../../shared/types/LoraDevice';
import { useTranslations } from '../../../../../shared/translations/useTranslations';
import apiService from '../../../../../shared/services/api';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

interface Props {
  loraDevices: LoraDevice[];
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

const BulkChangeSthsConfigurationModal = (props: Props) => {
  const { loraDevices, onClose, open, onSuccess } = props;
  const translations = useTranslations();
  const [isChangingConfiguration, setIsChangingConfiguration] = useState(false);
  const [savedDevices, setSavedDevices] = useState([]);
  const form = useForm({
    initialValues: {
      sthsActiveAlgorithm: 1,
      sthsPresenceThreshold: 1000,
      sthsPresenceHysteresis: 50,
      sthsMotionThreshold: 1000,
      sthsMotionHysteresis: 50,
    },
  });

  const handleChangeConfiguration = async formValues => {
    try {
      setIsChangingConfiguration(true);
      const values = {
        sthsActiveAlgorithm: Number(formValues.sthsActiveAlgorithm),
        sthsPresenceThreshold: Number(formValues.sthsPresenceThreshold),
        sthsPresenceHysteresis: Number(formValues.sthsPresenceHysteresis),
        sthsMotionThreshold: Number(formValues.sthsMotionThreshold),
        sthsMotionHysteresis: Number(formValues.sthsMotionHysteresis),
      };
      for (const loraDevice of loraDevices) {
        await apiService({}).put(
          `/api/lora/devices/${loraDevice.uuid}/configuration/sths_config`,
          values,
        );
        setSavedDevices(currentSavedDevices => [...currentSavedDevices, loraDevice]);
      }
      notifications.show({
        title: translations.global.configurationChanged,
        message: translations.global.messageConfigurationChanged,
        color: 'blue',
      });

      onSuccess();
    } catch (e) {
    } finally {
      setIsChangingConfiguration(false);
    }
  };

  return (
    <Modal opened={open} onClose={onClose} title={translations.global.changeTimeConfiguration}>
      <form onSubmit={form.onSubmit(handleChangeConfiguration)}>
        {/* <Form form={form} /> */}

        <NumberInput
          label="ActiveAlgorithm"
          description={'Wartości: 1, 2, 3'}
          min={1}
          max={3}
          required
          {...form.getInputProps('sthsActiveAlgorithm')}
          mb={10}
        />

        <NumberInput
          label="PresenceThreshold"
          description="Min 100, max 1000"
          min={100}
          max={1000}
          required
          {...form.getInputProps('sthsPresenceThreshold')}
          mb={10}
        />

        <NumberInput
          label="PresenceHysteresis"
          description="Min 10, max 10000"
          min={10}
          max={10000}
          required
          {...form.getInputProps('sthsPresenceHysteresis')}
          mb={10}
        />
        <NumberInput
          label="MotionThreshold"
          description="Min 100, max 1000"
          min={100}
          max={1000}
          required
          {...form.getInputProps('sthsMotionThreshold')}
          mb={10}
        />
        <NumberInput
          label="MotionHysteresis"
          description="Min 10, max 10000"
          min={10}
          max={10000}
          required
          {...form.getInputProps('sthsMotionHysteresis')}
          mb={10}
        />
        {isChangingConfiguration && (
          <Alert variant="outline" color="blue" mb={10} mt={10}>
            <Loader color="blue" mr={10} size="sm" />
            Zapisano {savedDevices.length} urządzeń z {loraDevices.length}
          </Alert>
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isChangingConfiguration} type="submit">
            {translations.global.save}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default BulkChangeSthsConfigurationModal;
