import React, { PureComponent } from 'react';
import map from 'lodash/map';
import { withRouter } from 'react-router';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import { translationService } from 'shared/translations/translations';
import { Link } from 'react-router-dom';

class CompanyPlaces extends PureComponent {
  goToDetails = id => () => {
    this.props.history.push(routes.places.manage.to(id));
  };

  render() {
    const { data } = this.props;
    const translations = translationService.getTranslations();
    return (
      <div className="row">
        <div className="col-md-12">
          <Table>
            <Thead>
              <Tr>
                <Th>{translations.global.name}</Th>
                <Th>{translations.global.address}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {map(data, place => (
                <Tr key={place.uuid}>
                  <Td>
                    <Link to={routes.places.manage.to(place.id)}>{place.name}</Link>
                  </Td>
                  <Td>{place.address}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyPlaces);
