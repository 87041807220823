import { Alert, Button, Group, Loader, Modal, NumberInput, Radio, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LoraDevice } from '../../../../../shared/types/LoraDevice';
import { useTranslations } from '../../../../../shared/translations/useTranslations';
import apiService from '../../../../../shared/services/api';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

interface Props {
  loraDevices: LoraDevice[];
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

function determineValue(value, inverted) {
  if (inverted) return 'inverted';
  return value ? 'true' : 'false';
}

const BulkChangeConfigurationTimeModal = (props: Props) => {
  const { loraDevices, onClose, open, onSuccess } = props;
  const translations = useTranslations();
  const [isChangingConfiguration, setIsChangingConfiguration] = useState(false);
  const [savedDevices, setSavedDevices] = useState([]);
  const form = useForm({
    initialValues: {
      confirmedStatusPeriod: 60,
      unconfirmedStatusPeriod: 60,
      failedSendRetryPeriod: 60,
      failedSendRetry: 3,
    },
  });

  const handleChangeConfiguration = async formValues => {
    try {
      setIsChangingConfiguration(true);
      const values = {
        confirmedStatusPeriod: Number(formValues.confirmedStatusPeriod),
        unconfirmedStatusPeriod: Number(formValues.unconfirmedStatusPeriod),
        failedSendRetryPeriod: Number(formValues.failedSendRetryPeriod),
        failedSendRetry: Number(formValues.failedSendRetry),
      };
      for (const loraDevice of loraDevices) {
        await apiService({}).put(
          `/api/lora/devices/${loraDevice.uuid}/configuration/connection`,
          values,
        );
        setSavedDevices(currentSavedDevices => [...currentSavedDevices, loraDevice]);
      }
      notifications.show({
        title: translations.global.configurationChanged,
        message: translations.global.messageConfigurationChanged,
        color: 'blue',
      });

      onSuccess();
    } catch (e) {
    } finally {
      setIsChangingConfiguration(false);
    }
  };

  return (
    <Modal opened={open} onClose={onClose} title={translations.global.changeTimeConfiguration}>
      <form onSubmit={form.onSubmit(handleChangeConfiguration)}>
        {/* <Form form={form} /> */}

        <NumberInput
          label="ConfirmedStatusPeriod"
          description={translations.global.timeInSeconds}
          min={60}
          required
          {...form.getInputProps('confirmedStatusPeriod')}
          mb={10}
        />
        <NumberInput
          label="UnconfirmedStatusPeriod"
          description={translations.global.timeInSeconds}
          min={60}
          required
          {...form.getInputProps('unconfirmedStatusPeriod')}
          mb={10}
        />
        <NumberInput
          label="failedSendRetryPeriod"
          description={translations.global.timeInSeconds}
          min={10}
          required
          {...form.getInputProps('failedSendRetryPeriod')}
          mb={10}
        />
        <NumberInput
          label="failedSendRetry"
          min={1}
          max={30}
          description={translations.global.numberOfTimes}
          required
          {...form.getInputProps('failedSendRetry')}
          mb={10}
        />
        {isChangingConfiguration && (
          <Alert variant="outline" color="blue" mb={10} mt={10}>
            <Loader color="blue" mr={10} size="sm" />
            Zapisano {savedDevices.length} urządzeń z {loraDevices.length}
          </Alert>
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isChangingConfiguration} type="submit">
            {translations.global.save}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default BulkChangeConfigurationTimeModal;
