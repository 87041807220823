import React, { PureComponent } from 'react';
import map from 'lodash/map';
import { withRouter } from 'react-router';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import flatMap from 'lodash/flatMap';
import { translationService } from 'shared/translations/translations';
import { Link } from 'react-router-dom';

class CompanySubCompanies extends PureComponent {
  goToDetails = id => () => {
    this.props.history.push(routes.companies.manage.to(id));
  };
  countDevices = company => {
    const devices = flatMap(company.places, place => place.devices);
    return devices.length;
  };
  render() {
    const translations = translationService.getTranslations();
    const { data } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <Table>
            <Thead>
              <Tr>
                <Th>{translations.global.name}</Th>
                <Th>{translations.global.address}</Th>
                <Th>{translations.global.facilityCount}</Th>
                <Th>{translations.global.devicesCount}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {map(data, company => (
                <Tr key={company.uuid}>
                  <Td>
                    <Link to={routes.companies.manage.to(company.uuid)}>{company.name}</Link>
                  </Td>
                  <Td>{company.address}</Td>
                  <Td>{company.placesCount || company.places.length}</Td>
                  <Td>{company.devicesCount || this.countDevices(company)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanySubCompanies);
